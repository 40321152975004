import { SEP_WORD, WORDS } from '../constants/wordlist'

export const isWordInWordList = (word: string) => {
  return (
    WORDS.includes(word.toLowerCase())
  )
}

export const isWinningWord = (word: string) => {
  return solution === word
}

export const getWordOfDay = () => {
  // January 1, 2022 Game Epoch
  // const epochMs = 1643113978753
  // const now = Date.now()
  // const msInDay = 86400000 / 2
  //const index = Math.floor((now - epochMs) / msInDay)
  //const index = Math.floor(WORDS.length * Math.random())

  var solutionLength = WORDS.indexOf(SEP_WORD)
  if (solutionLength < 0) solutionLength = WORDS.length
  var index = Math.floor(solutionLength * Math.random())
  //select index
  const hrefArr = document.location.href.split('?');
  const matched = hrefArr.length === 2 ? hrefArr[1].match(/no=(\d+)/) : null;
  if (matched && matched[1] && +matched[1] >= 0 && +matched[1] < solutionLength) {
    index = +matched[1];
  }

  return {
    solution: WORDS[index].toUpperCase(),
    solutionIndex: index,
    solutionLength: solutionLength
  }
}

export const { solution, solutionIndex, solutionLength } = getWordOfDay()
